import { MixlistProject } from "../MixlistProject.js";

const MixlistProjectPage = () => {
  return (
    <>
      <MixlistProject />
    </>
  );
};

export default MixlistProjectPage;
