import { G20Project } from "../G20Project.js";

const G20ProjectPage = () => {
  return (
    <>
      <G20Project />
    </>
  );
};

export default G20ProjectPage;
